<script>
    import Advertising from '@/services/Advertising'
    import Swal from "sweetalert2";

    export default {
        props: {
            sponsor_id: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                attribute_name: null,
                attribute_value: null,
                tryingToSubmit: false,
            };
        },
        methods: {
            refreshSponsorData() { 
                this.$emit('refreshScreen') //event from parent
            },
            addAttributes(){
                this.tryingToSubmit = true;
                Advertising.createSponsorAttributes(this.sponsor_id, {
                    attributes: [
                        {
                            attribute:  this.attribute_name, 
                            value:  this.attribute_value
                        }
                    ]
                })
                .then((response) => {
                    this.tryingToSubmit = false;
                    const res = response.data.data ? response.data.data : false;
                    const error = response.data.error ? response.data.error : '';
                    if(res){
                        Swal.fire("OK!", "Attribute Created !", "success")
                        this.refreshSponsorData();
                        this.closeModal();
                    }else{
                        Swal.fire("Fail!", error, "warning")
                    }
                })
                .catch(error => {
                    this.tryingToSubmit = false;
                    const errorMsg = error.response ? error.response : 'Failed';
                    Swal.fire("Fail!", errorMsg, "warning")
                })
            },
            closeModal() {
                this.showModal = false;
                this.attribute_name = null;
                this.attribute_value = null;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Add Success",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },
        }
    };
</script>



<template>
    <b-modal
        id="add_sponsor_attributes"
        v-model="showModal"
        title="Add New Attribute"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
        centered
    >
    <form @submit.prevent="addAttributes">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
                <label class="control-label form-label label-for">Attribute Name</label>
                <b-form-input  v-model="attribute_name"></b-form-input>
            </div>
            <div class="mb-3">
                <label class="control-label form-label label-for">Attribute Value</label>
                <b-form-input  v-model="attribute_value"></b-form-input>
            </div>
          </div>
        </div>

        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button type="submit" variant="primary" class="ms-3" @click="addAttributes" :disabled="tryingToSubmit">
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>