
<script>
    import Advertising from '@/services/Advertising'
    import Swal from "sweetalert2";
    //import Aws from '@/services/Aws'

    export default {
        props: {
            sponsor_id: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                tryingToSubmit: false,
                source_url: '',
                text: '',
                file: '',
                tootgleActive: 1
            };
        },

        methods: {

            closeModal() {
                this.showModal = false;
                this.source_url = '';
                this.file = '',
                this.text = '';
            },

            refreshScreen() { 
                this.$emit('refreshScreen') //event for parent
            },

            addSponsorMedia(){
                this.tryingToSubmit = true;

                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('source_url', this.source_url);
                formData.append('text', this.text);

                Advertising.addSponsoredMedia(this.sponsor_id, formData)
                .then((response) => {
                    this.tryingToSubmit = false
                    const res = response.data.id ? response.data.id : false
                    const error = response.error ? response.error : 'Failed'
                    if(res){
                        this.refreshScreen()
                        Swal.fire("Success!", "Sponsor Media Added", "success")
                        this.closeModal()
                    }else{
                        Swal.fire("Fail!", error, "warning")
                    }
                })
                .catch(error => {
                    const errorMsg = error.response.data.error ? error.response.data.error : 'Failed'
                    this.tryingToSubmit = false
                    Swal.fire("Fail!", errorMsg, "warning")
                });
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },

            selectFile(event){
                this.file = event.target.files[0]
                //this.source_url = URL.createObjectURL(this.file)

                // const fileName = this.sponsor_id + '_' + event.target.files[0].size + '_'  + event.target.files[0].name
                // const file = event.target.files[0]
                // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'sponsor/media/'
                // if(file) {
                //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
                //     .then((response) => {
                //         //console.log(response)
                //         this.source_url = response.fullPath
                //     })
                // }
            },

        }
    }
</script>


<template>
    <b-modal
        id="add_sponsor_media"
        v-model="showModal"
        title="Add New Sponsor Media"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
        centered
    >
    <form @submit.prevent="addSponsorMedia">
        <div class="row">
          <div class="col-12">

                <div  class="mb-3">
                    <input type="checkbox" id="customSwitchsizemd" class="form-check-input" true-value="1" false-value="0" v-model="tootgleActive">
                    <label for="customSwitchsizemd" class="form-check-label">
                        <span class="badge  font-size-14 ms-3 bg-soft-success" >
                            {{tootgleActive==1 ? 'File Upload' : 'Enter File URL' }}
                        </span> 
                    </label>
                </div>

                <div class="mb-3" v-if="tootgleActive==1">
                    <img v-if="source_url" :src="source_url"  width="200"   style="max-height: 150px;" class="avatar-lg" />
                    <img v-else  width="200" src="@/assets/images/image_not_found.png"  style="max-height: 150px;" class=" avatar-lg"  />
                    <br>
                    <b-form-file @change="selectFile"  type="file" class="mt-3" plain accept="image/jpeg, image/png, image/gif" ></b-form-file>
                </div>

                <div class="mb-3" v-if="tootgleActive==0">
                    <label class="control-label form-label label-for">Source URL</label>
                    <b-form-input  v-model="source_url"></b-form-input>
                </div>

                <div class="mb-3">
                    <label class="control-label form-label label-for">Media Text</label>
                    <b-form-input  v-model="text"></b-form-input>
                </div>

            </div>
        </div>

        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button 
                type="submit" 
                variant="primary" 
                class="ms-3" 
                :disabled="tryingToSubmit"
                >
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>