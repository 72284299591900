
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from '@/services/Advertising'
import Swal from "sweetalert2";
import modalAddSponsorAttributes from "@/components/modals/advertising/modalAddSponsorAttributes";
import modalAddSponsorMedia from "@/components/modals/advertising/modalAddSponsorMedia";
//import Aws from '@/services/Aws'

export default {
    components: {Layout,PageHeader, modalAddSponsorAttributes, modalAddSponsorMedia },
    page: {
        title: "Profile",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Sponsor Details",
            items: [
                {
                    text: "Advertising",
                    href: '/advertising/wall'
                },
                {
                    text: "Sponsor",
                    active: true,
                },
            ],
            sponsor_id: this.$route.params.id,
            sponsorData: {
                name: null,
                active: null,
                logo: null,
                file: '',
                url: null,
                description: null,
                created: null,
                attributes: null,
                media: null
            },
            error: null,
            tryToEdit: false,
            tryingToSubmit: false,
            slide: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "round",
            sortDesc: false,
            noResultsText: 'No Data Found',
            fieldsMedia: [
                {
                    key: "id",
                    label: "Media ID",
                    sortable: true,
                },
                {
                    key: "type",
                    label: "Media Type",
                    sortable: true,
                },
                {
                    key: "source_url",
                    label: "Image",
                    sortable: true,
                },
                {
                    key: "text",
                    label: "Media Text",
                    sortable: true,
                },
                {
                    key: "width",
                    label: "Media Width",
                    sortable: true,
                },
                {
                    key: "height",
                    label: "Media Height",
                    sortable: true,
                },
                {
                    key: "active",
                    label: "Active",
                    sortable: true,
                },
                {
                    key: "timestamp",
                    label: "Timestamp",
                    sortable: true,
                },
                "action",
            ],
        };
    },
    async created() {
        this.getSponsor()
    },
    methods: {

         /**
         * Search the table data with search input
         */
         onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        async getSponsor() {
            Advertising.getSponsorById(this.sponsor_id)
            .then(response => {
                this.sponsorData =  response.data.data[0];
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },
        
        toggle() {
            this.tryToEdit = !this.tryToEdit
        },

        confirmRemoveAttribute(attribute) {
            Swal.fire({
                title: "Are you sure?",
                //text: "This Match will no longer be featured !",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    Advertising.removeAttribute(this.sponsor_id, attribute)
                    .then((response) => {
                        const res = response.data.data[0].id ? response.data.data[0].id : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res && error==''){
                            this.getSponsor()
                            Swal.fire("Removed!", "Attribute Removed !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    })
                    .catch(error => {
                        Swal.fire("Fail!", error, "warning");
                    });
                }
            });
        },

        editSponsor(){
            this.tryingToSubmit = true;

            let formData = new FormData();
            formData.append('name', this.sponsorData.name)
            formData.append('active', this.sponsorData.active)
            formData.append('file', this.sponsorData.file)
            formData.append('url', this.sponsorData.url)
            formData.append('description', this.sponsorData.description)
            formData.append('attributes', this.sponsorData.attributes)
            formData.append('media', this.sponsorData.media)
            formData.append('_method', 'PATCH')

            Advertising.editSponsor(this.sponsor_id, formData)
            .then((response) => {
                this.tryingToSubmit = false;
                const res = response.data.data ? response.data.data : false;
                const error = response.data.error ? response.data.error : '';
                if(res){
                    this.tryToEdit = false
                    Swal.fire("Modified!", "Sponsor Modified !", "success")
                }else{
                    Swal.fire("Fail!", error, "warning")
                }
            })
            .catch(error => {
                this.tryingToSubmit = false;
                const errorMsg = error.response ? error.response : 'Failed';
                Swal.fire("Fail!", errorMsg, "warning")
            })
        },

        selectFile(event){
            //const fileName = event.target.files[0].name
            const file = event.target.files[0]
            this.sponsorData.file = file
            this.sponsorData.logo = URL.createObjectURL(file)
            // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'sponsor/'
            // if(file) {
            //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
            //     .then((response) => {
            //         //console.log(response)
            //         this.sponsorData.logo = response.fullPath
            //     })
            // }
        },


        modalAddAttributes() {
            this.$bvModal.show("add_sponsor_attributes");
        },

        removeMedia(sponsor_id, media_id){
            Swal.fire({
              title: "Are you sure?",
              text: "Remove From Media !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes, remove it!",
            }).then((result) => {
                if (result.value) {
                    Advertising.removeSponsoredMedia(sponsor_id, media_id)
                    .then((response) => {
                        const res   = response.data.data  ? response.data.data  : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res && error==''){
                            this.getSponsor()
                            Swal.fire("Removed!", "Media Removed !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    })
                    .catch(error => {
                        Swal.fire("Fail!", error, "warning");
                    });
                }
          });
        },

        modalAddSponsorMedia() {
          this.$bvModal.show("add_sponsor_media");
        },

    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.sponsorData.media.length;
        },
    },
    
};

</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        
        <div class="row mb-4">
            <div :class="tryToEdit ? 'col-xl-8'  : 'col-xl-12'" >
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <button class="float-end btn btn-light btn-sm" @click="toggle" type="button">Edit</button>
                            <img  v-if="sponsorData.logo" :src="sponsorData.logo" class="avatar-lg" width="200" style="max-height: 150px;" />
                            <img  v-else src="@/assets/images/image_not_found.png" class="avatar-lg" width="200"  style="max-height: 150px;" />
                            <!-- <h3 class="mt-3 mb-1">{{sponsorData.name}}</h3> -->
                            <br>
                            <p class="badge  font-size-14"  :class="[sponsorData.active==1 ? 'bg-soft-success' : 'bg-soft-danger']">
                                    {{sponsorData.active==1 ? 'Active' : 'Not Active' }}
                            </p> 
                            <div class="clearfix"></div>
                        </div>

                        <hr class="my-2" />

                        <div class="text-muted">

                            <div class="mb-3">
                                <h5 class="font-size-16 d-inline">Sponsor Name: </h5>
                                <span>{{sponsorData.name}}</span>
                            </div>

                            <div class="mb-3">
                                <h5 class="font-size-16 d-inline">Destination URL: </h5>
                                <span>{{sponsorData.url}}</span>
                            </div>

                            <div class="mb-3">
                                <h5 class="font-size-16 d-inline">Description: </h5>
                                <span>{{sponsorData.description}}</span>
                            </div>

                            <div class="mb-3">
                                <h5 class="font-size-16 d-inline">Created: </h5>
                                <span>{{sponsorData.created}}</span>
                            </div>
                            
                            <div class="mb-3">
                                <h5 class="font-size-16">Attributes:
                                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Add New Attribute" 
                                        @click="modalAddAttributes">
                                        <i class="uil-plus-circle font-size-22"></i>
                                    </a>
                                </h5> 
                                <p>
                                    <ul>
                                        <li v-for="attribute in sponsorData.attributes" :key="attribute.attribute">
                                            <strong>{{attribute.attribute}} = {{attribute.value}} ,</strong> 
                                            <span class="ms-3">Added on:  {{attribute.timestamp}}</span>
                                            
                                            <!-- <span class="ms-3"> -->
                                                <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove" 
                                                    @click="confirmRemoveAttribute(attribute.attribute)">
                                                    <i class="uil uil-trash-alt font-size-18"></i>
                                                </a>
                                            <!-- </span> -->
                                            
                                        </li>
                                    </ul>
                                
                                </p>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tryToEdit" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center font-size-18">
                            <strong>Edit Sponsor</strong>
                            <hr class="my-2" />
                        </div>
                            <form class="form-horizontal" role="form" @submit.prevent="editSponsor">
                                <div class="mb-3">
                                    <label class="control-label form-label label-for">Status</label>
                                    <div dir="ltr" class="form-check form-switch form-switch-md mb-3">
                                        <input type="checkbox" id="customSwitchsizemd" class="form-check-input" true-value="1" false-value="0" v-model="sponsorData.active">
                                        <label for="customSwitchsizemd" class="form-check-label">
                                            <span class="badge  font-size-14"  :class="[sponsorData.active==1 ? 'bg-soft-success' : 'bg-soft-danger']">
                                                {{sponsorData.active==1 ? 'Active' : 'Not Active' }}
                                            </span> 
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="control-label form-label label-for">Logo</label>
                                </div>
                                <div class="mb-3">
                                    <img v-if="sponsorData.logo" :src="sponsorData.logo"  width="200"   style="max-height: 150px;" class="rounded-circle avatar-lg" />
                                    <img v-else  width="200" src="@/assets/images/image_not_found.png"  style="max-height: 150px;" class="rounded-circle avatar-lg"  />
                                    <br>
                                    <b-form-file @change="selectFile"  type="file" class="mt-3" plain accept="image/jpeg, image/png, image/gif" ></b-form-file>
                                </div>
                                <div class="mb-3">
                                    <label class="control-label form-label label-for">Destination URL</label>
                                    <b-form-input  v-model="sponsorData.url"></b-form-input>
                                </div>
                                <div class="mb-3">
                                    <label class="control-label form-label label-for">Description</label>
                                    <b-form-textarea  v-model="sponsorData.description"   rows="5" ></b-form-textarea>
                                </div>

                                <div class="mb-3">
                                    <label class="control-label form-label label-for">Attributes:</label>
                                </div>
                                <hr class="my-2" />

                                <div class="mb-3" v-for="attribute in sponsorData.attributes" :key="attribute.attribute">
                                    <label class="control-label form-label">{{attribute.attribute}}</label>
                                    <b-form-input  v-model="attribute.value"></b-form-input>
                                </div>

                                <div class="mb-3">
                                    <b-button type="submit" variant="primary"  >
                                        Update
                                    </b-button>
                                </div>
                                
                            </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-xl-12">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                            <div>
                                <button type="button" class="btn btn-success mb-3" @click="modalAddSponsorMedia()">
                                    <i class="mdi mdi-plus me-1"></i> Add Media
                                </button>
                            </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <h5 class="font-size-16 d-inline">Media: </h5>
                            <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
                                <div class="row mt-5">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center fw-normal">
                                            Show&nbsp;
                                            <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                            ></b-form-select
                                            >&nbsp;entries
                                        </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                        >
                                        <label class="d-inline-flex align-items-center fw-normal">
                                            Search:
                                            <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                            ></b-form-input>
                                        </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                    <!-- Table -->
                                    <b-table
                                        table-class="table table-centered datatable table-card-list"
                                        thead-tr-class="bg-transparent"
                                        :items="sponsorData.media"
                                        :fields="fieldsMedia"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                        show-empty
                                        empty-text="No Data Found"
                                        empty-filtered-text="No Data Found"
                                        >
                                        <template v-slot:cell(source_url)="data">
                                            <img class="img-fluid" width="100" :src="data.item.source_url" >
                                        </template> 
                                        <template v-slot:cell(action)="data">
                                            <ul class="list-inline mb-0">
                                                <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Media" @click="removeMedia(data.item.sponsor_id, data.item.id)">
                                                    <i class="uil uil-trash-alt font-size-18"></i>
                                                </a>
                                            </ul>
                                        </template>
                                    </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- MODALS --> 
     <modalAddSponsorAttributes 
        :sponsor_id="sponsor_id" 
        @refreshScreen="getSponsor"
    >
    </modalAddSponsorAttributes>

    <modalAddSponsorMedia 
        :sponsor_id="sponsor_id"
        @refreshScreen="getSponsor" >
    </modalAddSponsorMedia>
    <!-- END  MODALS --> 

    </Layout>
</template>
    